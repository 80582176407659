﻿
@import '@styles/shared/var';

.animation {
    overflow: hidden;
}

.animationOpacity {
    opacity: 0;
}

.flyInAnimation {
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0%) translateX(0%);
    animation-name: flyInUp; // default

    &.slow {
        animation-duration: 0.8s;
    }    
    
    &.fast {
        animation-duration: 0.2s;
    }

    &.up {
        animation-name: flyInUp;
    }

    &.left {
        animation-name: flyInLeft;
    }
}

.flyOutAnimation {
    animation-duration: 0.8s;
    animation-timing-function: ease-out;
    opacity: 0;
    animation-name: flyOutDown; // default

    &.slow {
        animation-duration: 0.6s;
    }

    &.down {
        //animation-direction: reverse;
        animation-name: flyOutDown;
        transform: translateY(600px);
    }

    &.right {
        animation-name: flyOutRight;
        transform: translateX(600px);
    }
}

@keyframes flyInUp {
    0% {
        transform: translateY(600px);
        opacity: 0;
        filter: blur(4px);
    }

    80% {
        filter: blur(2px);
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
        filter: blur(0px);
    }
}

@keyframes flyInLeft {
    0% {
        transform: translateX(600px);
        opacity: 0;
        filter: blur(4px);
    }

    80% {
        filter: blur(2px);
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
        filter: blur(0px);
    }
}

@keyframes flyOutDown {
    0% {
        transform: translateY(0%);
        opacity: 1;
        filter: blur(0px);
    }

    80% {
        filter: blur(2px);
    }

    100% {
        transform: translateY(600px);
        filter: blur(4px);
        opacity: 0;
    }
}

@keyframes flyOutRight {
    0% {
        transform: translateX(0%);
        opacity: 1;
        filter: blur(0px);
    }

    80% {
        filter: blur(2px);
    }

    100% {
        transform: translateX(600px);
        filter: blur(4px);
        opacity: 0;
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.6s;
    opacity: 1;

    &.slow {
        animation-duration: 1.4s;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeOut {
    animation-name: fadeOut;
    animation-duration: 0.4s;
    opacity: 0;
    display: none;

    &.slow {
        animation-duration: 0.8s;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        display: block;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

.rollDown {
    animation-name: rollDown;
    animation-duration: 0.2s;
    transform-origin: top;
    opacity: 1;
    transform: scaleY(1);
}

@keyframes rollDown {
    0% {
        transform: scaleY(0);
        opacity: 0;
    }

    100% {
        transform: scaleY(1);
        opacity: 1;
    }
}

.rollOutUp {
    animation-name: rollOutUp;
    animation-duration: 0.3s;
    transform-origin: top;
    opacity: 0;
    transform: scaleY(0);
    display: none;
}

@keyframes rollOutUp {
    0% {
        transform: scaleY(1);
        display: block;
        opacity: 1;
    }

    100% {
        transform: scaleY(0);
        opacity: 0;
        display: none;
    }
}
