﻿

// BARVY
$black: #464646;
$black: #363636;
$black: #262626;

$white: #fdfdfd;

$gray: #F0F0F0;
$grayDarker: #738079;
$grayLighter: #f8f8f8;
$grayDarkerButABitLighter: #dbdbdb;

$red: #ff0000;
$redDarker1: #bc000f;
$redDarker2: #952520;
$redDarker3: #7c000f;

$green: #0aa147;

$orange: #FF5F15;
$orange: #ff7433;

// SIZES
$smExtraBreakpoint: 554px;
$smBreakpoint: 576px;
$mdBreakpoint: 768px;
$lgBreakpoint: 992px;
$xlBreakpoint: 1200px;
$xxlBreakpoint: 1400px;
$xxxlBreakpoint: 1700px;

$navHeight: 61px; // default (mobile)
$navHeightPc: 71px;

$baseScreenWidth: 1920px;
$scaleRatio: 600; // cim mensi cislo, tim je scalovani vetsi. tzn vetsi zmeny oproti zakladu
$scaleProtozeNaPredlozeJsouTextyVetsiAJeToDivny: 1;

@function scalePx($baseFontSize) {
    @return calc($baseFontSize - ($baseScreenWidth - 100vw)/$scaleRatio);
}
