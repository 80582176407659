
@import '@styles/shared/var';
@import 'animations';

* {
    font-family: 'Arial Nova', 'Arial Unicode MS', Arial;
    font-weight: 400;
    font-style: normal;
    color: $black;
}

a {
    text-decoration: none;
}

.customShadow {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px!important
}

.loc {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
        outline: none !important;
    }
}

body {
    background: $white;

    &.noScroll {
        overflow: hidden!important;
    }
}

.containerCustomMain {
    width: 100%;
    margin-inline: auto;
    padding-inline: 24px;
}

.containerCustomMainBigger {
    width: 100%;
    margin-inline: auto;
    padding-inline: 24px;
}

.containerCustom {
    width: 100%;
    margin-inline: auto;
}

.containerCustomSm {
    padding-inline: 0px;
}

ul {
    list-style-type: square;
    padding-left: 1.4rem;

    li::marker {
        font-size: scalePx(22px);
        color: $red;
    }
}

a.link {
    text-decoration: none;
    color: $black;

    &:hover {
        color: $black;
    }
}

.fitW {
    width: fit-content;
}

.fitH {
    height: fit-content;
}

.green {
    background-color: $green;
    fill: $green;
}

.red {
    background-color: $red;
    fill: $red;
}

.transparent {
    background-color: transparent;
}

.gray {
    background-color: $gray;
    fill: $gray;
}

.grayDarker {
    background-color: $grayDarker;
    fill: $grayDarker;
}

.white {
    color: $white;
}

.inputError {

    &.span {
        color: red;
    }

    input, textarea, select {
        border-color: $red !important;
    }
}

input, textarea {
    outline: none !important;
    border-width: 1px;
}

.borderRed {
    border-color: rgba($red, 0.4)!important;
}

.glass {
    background-color: $gray;
    z-index: 22222;
    opacity: 0.8;
}

.whiteText {
    color: $white;
}

.redText {
    color: $red;
}

.greenText {
    color: $green;
}

.orangeText {
    color: $orange;
}

.blackText {
    color: $black;
}

p {
    //font-size: scalePx(18px);
}

h1 {
    font-size: scalePx(32px);
    color: rgba($red, 0.92);
    font-weight: bold;
    margin-bottom: 2.6vh;
}

h2 {
    font-size: scalePx(28px);
    font-weight: bold;
    margin-bottom: 1.2vh;
}

h3 {
    font-size: scalePx(24px);
    font-weight: bold;
    margin-bottom: 0.8vh;
}

h4 {
    font-size: scalePx(22px);
    font-weight: bold;
    margin-bottom: 0.2vh;
}

h5 {
    //font-size: scalePx(20px);
    font-size: 20px;
}

p {
    text-align: justify;
    color: $black;
    margin-bottom: 0.6rem;
    line-height: 1.7rem;
}


// MEDIA QUERRIES

@media only screen and (min-width: $smBreakpoint) {
    .containerCustomMain {
        width: 96%;
    }

    .containerCustomMainBigger {
        width: 96%;
    }
}

@media only screen and (min-width: $mdBreakpoint) {

    .containerCustomMain {
        width: 94%;
    }

    .containerCustomMainBigger {
        width: 96%;
    }
    .containerCustom {
        width: 100%;
    }

    .containerCustomSm {
        //padding-inline: 10px;
    }
}

@media only screen and (min-width: $lgBreakpoint) {
    .containerCustomMain {
        width: 86%;
    }

    .containerCustomMainBigger {
        width: 94%;
    }
}

@media only screen and (min-width: $xlBreakpoint) {
    .containerCustomMain {
        width: 76%;
    }

    .containerCustomMainBigger {
        width: 86%;
    }
}

@media only screen and (min-width: $xxlBreakpoint) {
    .containerCustomMain {
        width: 60%;
    }

    .containerCustomMainBigger {
        width: 70%;
    }
}

@media only screen and (min-width: $xxxlBreakpoint) {
    .containerCustomMainBigger {
        width: 60%;
    }
}